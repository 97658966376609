getTemplatesList
<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Templates Statuses'" :title="'Templates Statuses List'">
      <div class="list__buttons">
        <MainButton @click.native="handleCreate()">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="templatesStatusesList.length">
          <CustomTable
            v-if="templatesStatusesList.length"
            :data="templatesStatusesList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="handleDelete"
            :handle-edit="handleEdit"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :order="orderSort"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="templatesStatuses.lastPage"
              :page="page"
              :dataList="templatesStatuses"
              :urlList="'TEMPLATES_STATUSES_LIST'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Templates Statuses found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Templates Status - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">
        Templates Status {{ deleteRow.name }} successfully deleted.
      </div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";

export default {
  name: "TemplatesStatusesList",
  metaInfo: {
    title: "Templates Statuses List",
  },
  data() {
    return {
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "id",
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "updated_at",
            to: "Updated At",
          },
        ],
        customOrder: ["id", "name", "updated_at", "null"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
  },
  mounted() {
    this.getList();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("references", [
      "error",
      "loadingProcess",
      "templatesStatuses",
      "templatesStatusesList",
    ]),
    page() {
      return +this.$route.params.page;
    },
  },
  methods: {
    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      this.$store.dispatch("references/getTemplatesStatuses", url);
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.TEMPLATES_STATUS_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.TEMPLATES_STATUS_CREATE,
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}?soft=1`;
      this.$store.commit("references/setLoading", true);
      referencesApi
        .deleteTemplatesStatus(url)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header {
  grid-template-columns: 0.4fr 1fr 1fr 1fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr 1fr !important;
}
</style>
